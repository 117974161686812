import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

import TranslationStore from '../../../stores/translationStore'
import modalStore from '../../../stores/modalStore'
import Flag from '../Flag'
import ExpandedMenu from '../../Easymillions/Menu/ExpandedMenu'
const LoggedOffHeader = ({ translationStore, modalStore, ...props }) => {
	const { translate } = translationStore

	const magazineLinks = [{ title: translate('header_blog'), url: '/blog' }]

	return (
		<div className='text-right h-full mx-auto py-2 md:py-0'>
			<div className='bg-login-button-blue inline-block align-middle rounded h-8'>
				<button onClick={modalStore.showLogin} type='button' className='w-32 text-white h-full'>
					{translate('login')}
				</button>
			</div>

			<div className='inline-block h-6 ml-5' style={{ width: '1px' }}>
				<hr className='bg-grey w-full h-full' />
			</div>
			<div className='inline-block ml-5'>
				<Flag />
			</div>
			<div className={`absolute desktop-header`}>
				<img src='https://cdn.thunderchild.es/easymillions-fr/images/stamp-smal-new.png' width='80px' />
			</div>

			<ExpandedMenu
				showNotification
				blogFromPublic
				links={{
					magazineLinks
				}}
			/>
		</div>
	)
}

LoggedOffHeader.propTypes = {
	translationStore: PropTypes.instanceOf(TranslationStore).isRequired,
	modalStore: PropTypes.instanceOf(modalStore).isRequired
}

export default inject('translationStore', 'modalStore')(observer(LoggedOffHeader))
